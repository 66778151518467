/* Mobile first */

/* ADDS: */
/* Create logo with Logo.js. Can convert html into png (https://convertio.co/html-png/) */

body {
  font-size: 20px;
  line-height: 1.75;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background-color: #080808;
  color: white;
  margin: 0px;
}

/* .App {
  
} */




/* text */
.poppins {
  font-family: 'Poppins', sans-serif;
}



h1, h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 1.5em;
  color: #fff;
}

h1, h2, p {
  margin-top: 0px;
}

.h1 {
  font-size: 1.5em;
  font-weight: 500;
}

.h2 {
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 2em;
}


.fontSize07em {
  font-size: 0.7em;
}




.colorChangeText {
  /* background: linear-gradient(to right, #7507f7 20%, #b2f545 40%, #b2f545 60%, #7507f7 80%); */
  background: linear-gradient(to right, #7507f7 5%, #fff, #b2f545);
  /* background-size: 200% auto; */
  background-size: 100% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* animation: colorMove 2.3s linear infinite; */
}

.colorChangeText1 {
  background: linear-gradient(to right, #7507f7 5%, #fff);
  background-size: 100% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.colorChangeText2 {
  background: linear-gradient(to right,#fff, #b2f545);
  background-size: 100% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



@keyframes colorMove {
  to {
    background-position: 200% 0%;
  }
}


a, 
button,
.links {
  color: #fff;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.logoText {
  width: 3.6em;
  height: 3.6em;
  font-size: 0.8em;
  font-family: 'Oregano', cursive;
  line-height: 1;
  color: #fff;
  /* background: linear-gradient(133deg, #7507f7, #b2f545) !important; */
  box-shadow: 
  -2px -2px 0px 0px #7507f7, 
  2px 2px 0px 0px #b2f545;
  border-radius: 50%;
  transition: box-shadow 0.6s;
  align-items: center;
}
.logoText:hover {
  box-shadow: 
  -2px -2px 0px 0px #b2f545, 
  2px 2px 0px 0px #7507f7;
}







.semiBold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}


.textAlignCenter {
  text-align: center;
}


.underline {
  text-decoration: underline;
}



/* Colors */
.colorWhite {
  color: #fff;
}

.colorPurple {
  color: #7507f7;
  /* #7100ed, #6D01E4, */
}

.colorLime {
  color: #b2f545;
}

.colorRed {
  color: #ff3333;
}




.backgroundColorTransparent {
  background-color: transparent;
}

.backgroundColorBlack0 {
  background-color: #080808;
}





.width100Percent {
  width: 100%;
}





/* Flex classes */
.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}





.positionRelative {
  position: relative;
}






/* margin */
.marginAuto {
  margin: auto;
}

.allDivsMarginAuto * {
  margin: auto;
}


.marginTop20 {
  margin-top: 20px;
}


.marginBottom0 {
  margin-bottom: 0px !important;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom2em {
  margin-bottom: 2em;
}




/* Buttons */
.btns {
  background-color: transparent;
  font-size: 1em;
  font-weight: 400;
  box-shadow: 
  -2px -2px 0px 0px #7507f7, 
  2px 2px 0px 0px #b2f545;
  padding: 0.6em 1.6em;
  border-radius: 1.2em;
  transition: box-shadow 0.6s;
  cursor: pointer;
}
.btns:hover {
  box-shadow: 
  -2px -2px 0px 0px #b2f545, 
  2px 2px 0px 0px #7507f7;
}

.btns.noStyle {
  font-size: 1em;
  box-shadow: none !important;
  padding: 0px;
  border-radius: 0px;
  transition: color 0.3s;
}
.btns.noStyle:hover {
  color: #b2f545;
}


.btns.social {
  margin: 15px;
}

.btns.social svg {
  font-size: 100%;
  width: 1.8em;
  height: 1.8em;
}







.pages { 
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 4em;
  /* background-attachment: scroll; */
  /* scroll to turn off */
  /* background-repeat: no-repeat; */
}



.sections {
  width: 90%;
  margin: auto;
  background-color: #101010;
  /* box-shadow: 0px 0px 5px 0px #000; */ /* #7a00ffc2, #131313 */
  border-radius: 20px;
  margin-bottom: 5em;
  /* z-index: 2; */
}

.sections.noBackgroundColor {
  background-color: transparent;
  box-shadow: none;
}

.containers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em 1.5em;
  text-align: left;
  margin: auto;
}

.sections.languages .containers,
.sections.recentWork .containers,
.sections.contact .containers {
  padding-top: 3.5em;
  padding-bottom: 3.5em;
}

.sections.recentWork .containers {
  padding-bottom: 0em;
}

.containers.backgroundColor {
  background-color: #101010;
  border-radius: 20px;
}








/* Navbar */
#navbar {
  width: 100vw;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #080808;
  box-shadow: 0px 0px 5px 0px #000000;
  z-index: 1;
}

#navbar .containers {
  width: 93%; /* 90% */
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}








/* Home */
.sections.hero {
  display: flex;
  flex-direction: column;
}


.sections.hero .containers,
.sections.recentWork .containers.two {
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.sections.hero .containers.two {
  align-items: center;
}

.sections.hero .grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
  row-gap: 4em;
  align-items: center;
  justify-content: center;
  /* margin-right: -3%; */
}


.heroImages {
  width: auto;
  height: calc(100vw/4.5); /* 2.6, 4 */
  margin: auto;
}


/* .heroImg {
  width: 70%;
  height: auto;
  margin: auto;
} */

.rotate {
  -webkit-animation: rotate 15s linear infinite;
  -moz-animation: rotate 15s linear infinite;
  -o-animation: rotate 15s linear infinite;
  animation: rotate 15s linear infinite; 
}

.rotate.fast {
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
}

@keyframes rotate {
  from { transform: rotate(-360deg); }
  to { transform: rotate(360deg); }
}







.codingTitlesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-row-gap: 2em;
}

.codingTitles {
  width: 4.5em;
  height: 4.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  box-shadow: 
  0px 0px 0px 3px #7507f7; /* box-shadow: 
  -2px -2px 2px 0px #7100ed, 2px 2px 2px 0px #b2f545; */
}

.codingTitles p {
  font-size: 90%;
  font-weight: 700;
  line-height: 1.2;
  font-family: 'Play', sans-serif;
  color: #fff;
  margin: 0px;
}

/* .colorOrange0 {
  box-shadow: 0px 0px 5px 1px #ff6933;
}
.colorOrange1 {
  box-shadow: 0px 0px 5px 1px #ff4421;
}
.colorBlue0 {
  box-shadow: 0px 0px 5px 1px #00d8ff;
}
.colorBlue1 {
  box-shadow: 0px 0px 5px 1px #0096ff;
}
.colorYellow {
  box-shadow: 0px 0px 5px 1px #e6c745;
} */


@keyframes bounceRight {
  0% {
    transform: translateX(25px);
  }

  100% {
    transform: translateX(0px);
    }
}

/* .animateRightText1 {
  animation: bounceRight 1.3s infinite alternate-reverse;
  animation-delay: 0.5s;
}

.animateRightText2 {
  animation: bounceRight 1.3s infinite alternate-reverse;
  animation-delay: 1s;
}

.animateRightText3 {
  animation: bounceRight 1.3s infinite alternate-reverse;
  animation-delay: 1.5s;
} */





/* Recent work */
.recentWork .containers {
  padding-bottom: 1em;
}

.recentWork .grid {
  /* width: 100%; */
  /* display: grid;
  grid-template-columns: 1fr;
  row-gap: 4em; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.recentWorkCard {
  width: 230px;
  height: 230px;
  display: inline-block;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;
  margin: 0px 0em 4em;
}

.imageDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.titleDiv {
  height: 15%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 1em;
}


/* .sections.recentWork .containers.two {
  align-items: center;
} */

/* .recentWorkUl {
  width: 100%;
  text-align: center;
  list-style: none;
  padding: 0em;
  margin: 0em;
}

.recentWorkUl li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
} */

.customBullets {
  /* width: 7px; */
  height: 1.2em;  /* 7px */
  /* box-shadow: 
  -2px -2px 0px 0px #b2f545, 
  2px 2px 0px 0px #b2f545; #7507f7 */
  /* background: linear-gradient(to right, #7507f7 50%,#b2f545 50%); */
  /* border-radius: 50%; */
  margin-right: 15px;
  /* padding: 1.7px; */
}




form {
  width: 100%;
}


.formRow1 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.7em;
  margin-bottom: 0.7em;
}

.inputs {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: 1em;
  color: #fff;
  /* text-indent: 10px; */
  border-radius: 10px;
  background-color: #202020;
  padding: 15px;
  border: none;
  margin: 0px;
}


.inputs:focus {
  outline: 0px !important;
  -webkit-outline: 0px !important;
  -moz-outline: 0px !important;
  -o-outline: 0px !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  box-shadow: 
  -2px -2px 0px 0px #7507f7, 
  2px 2px 0px 0px #b2f545 !important;
  -webkit-box-shadow: 
  -2px -2px 0px 0px #7507f7, 
  2px 2px 0px 0px #b2f545 !important;
  -moz-box-shadow: 
  -2px -2px 0px 0px #7507f7, 
  2px 2px 0px 0px #b2f545 !important;
  -o-box-shadow: 
  -2px -2px 0px 0px #7507f7, 
  2px 2px 0px 0px #b2f545 !important;
}

.inputs.notTextArea {
  width: inherit;
  height: 3em;
  box-sizing: border-box;
}

.inputs.textArea {
  margin-bottom: 2em;
}

.submitBtn {
  display: block;
  margin: auto;
}




/* Footer */
#footer .sections {
  margin-bottom: 1em;
  background-color: transparent !important;
}

#footer .containers {
  padding-top: 2em;
  padding-bottom: 1em;
}

.copyRight {
  font-size: 0.7em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #fff;
}






/* iPhone */
@media screen and (min-width: 375px) {


  .sections.hero .containers.one {
    align-self: flex-start !important;
    margin: 0px !important;
  }

  .sections.hero .containers.one p {
    max-width: 650px !important;
  }





  .recentWorkCard {
    width: 262px !important;
    height: 262px !important;
    margin: 0px 2em 4em !important;
  }
}






@media screen and (max-width: 413px) {
  .onlyUnder414 {
    display: inline !important;
  }

  .notUnder414 {
    display: none !important;
  }
}



@media screen and (min-width: 414px) {
  .onlyUnder414 {
    display: none !important;
  }

  .notUnder414 {
    display: inline !important;
  }
}





/* Tablet */
@media screen and (min-width: 768px) {

  .h1 {
    font-size: 1.7em !important;
  }




  .sections.hero .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

  .heroImages {
    height: 5em !important;
  }



  .codingTitlesContainer {
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-row-gap: 3em;
  }



  
/* 
  .recentWork .grid {
    grid-template-columns: 1fr 1fr !important;
    column-gap: 4em !important;
  } */





  .formRow1 {
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 0.7em !important;
  }


  .btns.social svg {
    width: 1.5em !important;
    height: 1.5em !important;
  }
}






/* Small desktop */
@media screen and (min-width: 1024px) {
  body {
    /* font-size: 22px !important; */
  }



  #navbar {
    height: 85px !important;
  }





  .codingTitlesContainer {
    justify-content: space-evenly !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
    grid-row-gap: 3em;
  }





  .recentWorkCard {
    /* width: 300px !important; */
    /* height: 300px !important; */
    margin: 0px 2.5em 3.5em !important;
  }

  .titleDiv {
    height: 100% !important;
    transition: height 0.5s ease !important;
  }
  
  .recentWorkCard:hover .titleDiv {
    height: 15% !important;
  }
}







/* Laptop */
@media screen and (min-width: 1200px) {
  .h1 {
    font-size: 2em !important;
  }

  .h2 {
    margin-bottom: 2em !important;
  }
  
  
  
  #navbar {
    height: 95px !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }


  /* .logoText {
    width: 3.5em !important;
    height: 3.5em !important;
  } */



  .pages {
    padding-top: 6em !important;
  }



  .sections.hero,
  .sections.languages,
  .sections.recentWork {
    width: 78% !important;
  }


  .sections.languages,
  .sections.recentWork  {
    margin-bottom: 7em !important;
  }




  .sections.hero {
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-bottom: 8em !important;
  }

  .sections.hero .containers.one {
    width: 52% !important;
    /* align-self: flex-start !important; */
    margin: 0px !important;
    /* padding-right: 10% !important; */
  }

  .sections.hero .containers.two {
    width: 35% !important;
    /* justify-content: flex-end !important; */
    /* padding: 0px !important; */
    margin: 0px !important;
  }

  /* .heroImg { */
    /* width: 75% !important; */
    /* margin: 0px auto !important; */
  /* }   */

  .sections.hero .grid {
    grid-template-columns: 1fr 1fr !important;
    row-gap: 7em !important;
  }



  .codingTitlesContainer {
    max-width: 800px !important;
  }




  .recentWorkCard {
    margin: 0px 2.2em 4em !important;
  }



  .sections.contact {
    width: 60% !important;
  }

  .sections.contact form {
    width: 90% !important;
  }

}







/* Desktop */
@media screen and (min-width: 1440px) {
  body {
    /* font-size: 24px !important; */
  }

  .sections,
  #navbar .containers {
    /* max-width: 1440px !important; */
  }



  .sections.contact {
    width: 55% !important;
  }

  .sections.contact form {
    width: 85% !important;
  }
}